var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-fluid general-component-wrap-background min-height-100vh"},[_c('div',{staticClass:"columns mt-0 pt-5"},[_c('div',{staticClass:"column pt-0 pr-4 is-12",staticStyle:{"margin-top":"-0.75rem"}},[_c('div',{staticClass:"is-flex py-2"}),_vm._l((_vm.$parent.jobs_requests),function(job){return _c('table',{key:job.Number,staticClass:"add-materials-table is-fullwidth is-narrow",staticStyle:{"border-collapse":"collapse"}},[_c('thead',{staticClass:"th-sticky"},[_c('tr',[_c('td',{staticClass:"m-0 p-0",attrs:{"colspan":"9"}},[_c('p',{staticClass:"is-size-5 has-text-info-dark is-block has-fullwidth p-2",class:{
                    'request-confirmed':
                      job.JobState.ID == 1 || job.JobState.ID == 6,
                    'request-not-confirmed':
                      job.JobState.ID != 1 && job.JobState.ID != 6,
                  }},[_c('i',{staticClass:"fas fa-boxes mr-2"}),_vm._v(" "+_vm._s(_vm.$t("request"))+" "),_c('span',{staticClass:"has-text-grey"},[_vm._v(_vm._s(job.Number))]),_c('small',{staticClass:"ml-2 has-text-grey-light",staticStyle:{"font-weight":"normal"}},[_vm._v(_vm._s(job.Arranger.DisplayName))]),(job.JobState)?_c('span',{staticClass:"is-pulled-right mr-2"},[(job.JobState.ID != 1 && job.JobState.ID != 6)?_c('i',{staticClass:"far fa-clock mr-2"}):_c('i',{staticClass:"fa fa-check-square"}),(job.JobState.ID != 1 && job.JobState.ID != 6)?_c('span',[_vm._v(_vm._s(job.JobState.Caption))]):_vm._e()]):_vm._e()])])]),(job.expanded)?_c('tr',{staticClass:"has-text-info-dark general-component-wrap-background"},[_c('th',{staticClass:"has-text-info-dark",attrs:{"width":"7%"}},[_vm._v(_vm._s(_vm.$t("group")))]),_c('th',{staticClass:"has-text-info-dark",attrs:{"width":"25%"}},[_vm._v(" "+_vm._s(_vm.$t("material"))+" ")]),_c('th',{staticClass:"has-text-info-dark",attrs:{"width":"100px"}},[_vm._v(" "+_vm._s(_vm.$t("amount"))+" ")]),_c('th',{staticClass:"has-text-info-dark",attrs:{"width":"25%"}},[_vm._v(" "+_vm._s(_vm.$t("from_until"))+" "),_c('span',{staticClass:"is-pulled-right"},[_vm._v(" "+_vm._s(_vm.$t("notes"))+" ")])]),_c('th',{attrs:{"width":"10%"}}),_c('th',{staticClass:"has-text-right has-text-info-dark",attrs:{"width":"200px","colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("accepted"))+" ")]),_c('th',{staticClass:"has-text-info-dark has-text-centered"},[_vm._v(" "+_vm._s(_vm.$t("requested"))+" ")])]):_vm._e()]),(job.expanded)?_c('tbody',_vm._l((job.materials),function(material,index){return _c('tr',{key:index,staticClass:"tr-border has-text-dark",class:{
                'general-component-wrap-background':
                  material.date_amount && material.date_amount[0].m_id,
                'has-background-white':
                  !material.date_amount || !material.date_amount[0].m_id,
              }},[_c('td',{staticClass:"is-size-7 is-centered"},[_c('b',[_vm._v(_vm._s(_vm.getGroup(index, job.materials)))])]),_c('td',[_c('small',{staticClass:"has-text-grey-light"},[_vm._v(" "+_vm._s(material.Category)+" ")]),_c('br'),_vm._v(" "+_vm._s(material.Caption)+" ")]),_c('td',{staticClass:"pr-3"},[_c('div',{staticClass:"field"},_vm._l((material.date_amount),function(d,indexa){return _c('div',{key:indexa,staticClass:"control is-hover"},[_c('div',{},[_c('div',{staticClass:"field has-addons mb-1 add-materials-table-input",staticStyle:{"padding-right":"1px"}},[_c('p',{staticClass:"control",class:{
                            'material-assigned-warehouse':
                              d.warehouse_job != null ||
                              d.m_connected_project_id != null,
                          },staticStyle:{"width":"100%"}},[(
                              d.warehouse_job == null &&
                              d.m_connected_project_id == null &&
                              _vm.am_allowed('request.can_edit')
                            )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(d.Qty),expression:"d.Qty"}],staticClass:"input is-small is-hover",class:{
                              'general-component-wrap-background':
                                d.Qty == d.QtyWas &&
                                !_vm.has_changed_dates(d.dates, d.date_was),
                            },staticStyle:{"width":"80px"},attrs:{"type":"number"},domProps:{"value":(d.Qty)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(d, "Qty", $event.target.value)}}}):_vm._e(),(
                              d.warehouse_job == null &&
                              !_vm.am_allowed('request.can_edit')
                            )?_c('small',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(d.Qty)+" ")]):_vm._e(),(d.warehouse_job || d.m_connected_project_id)?_c('small',{staticClass:"p-1 has-text-dark"},[_vm._v(_vm._s(d.Qty))]):_vm._e()]),_c('p',{staticClass:"control"},[(
                              d.Qty != d.QtyWas &&
                              d.warehouse_job == null &&
                              d.m_connected_project_id == null
                            )?_c('a',{staticClass:"button is-small",on:{"click":function($event){$event.preventDefault();d.Qty = d.QtyWas}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e()])])])])}),0)]),_c('td',[_vm._l((material.date_amount),function(d,indexu){return _c('div',{key:indexu,staticClass:"is-flex is-hover"},[_c('div',{staticClass:"tile is-6 field has-addons has-addons-centered mb-1 is-fullwidth add-materials-table-input",staticStyle:{"padding-right":"1px","min-width":"300px"}},[_c('p',{staticClass:"control is-expanded",class:{
                        'material-assigned-warehouse':
                          d.warehouse_job != null ||
                          d.m_connected_project_id != null,
                      }},[(
                          d.warehouse_job == null &&
                          d.m_connected_project_id == null
                        )?_c('span',[(_vm.am_allowed('request.can_edit'))?_c('date-picker',{attrs:{"format":"ddd DD.MM.YYYY","lang":_vm.$i18n.locale,"type":"date","value-type":"date","disabled-date":_vm.disabledOutOfJobDates,"range":"","input-class":{
                            'input is-small is-fullwidth': true,
                            'general-component-wrap-background':
                              !_vm.has_changed_dates(d.dates, d.date_was),
                          }},on:{"change":function($event){return _vm.fixIfWrong(d)}},model:{value:(d.dates),callback:function ($$v) {_vm.$set(d, "dates", $$v)},expression:"d.dates"}}):_c('small',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm._f("customdate")(d.dates[0]))+" - "+_vm._s(_vm._f("customdate")(d.dates[1]))+" ")])],1):_c('small',{staticClass:"p-1 has-text-dark"},[_vm._v(_vm._s(_vm._f("customdate")(d.dates[0]))+" - "+_vm._s(_vm._f("customdate")(d.dates[1])))])]),_c('p',{staticClass:"control"},[(
                          _vm.has_changed_dates(d.dates, d.date_was) &&
                          d.warehouse_job == null
                        )?_c('a',{staticClass:"button is-small",on:{"click":function($event){$event.preventDefault();d.dates = d.date_was}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e()])]),(
                      (_vm.has_changed_dates(d.dates, d.date_was) ||
                        d.Qty != d.QtyWas) &&
                      d.warehouse_job == null
                    )?_c('a',{staticClass:"button is-success is-small ml-1",on:{"click":function($event){$event.preventDefault();return _vm.addMaterialDateRequest(material, job, 0)}}},[_c('i',{staticClass:"far fa-save"})]):_vm._e(),(
                      d.warehouse_job == null &&
                      d.m_connected_project_id == null &&
                      _vm.am_allowed('request.can_delete')
                    )?_c('a',{staticClass:"has-text-danger ml-auto pt-1 is-pulled-right",attrs:{"disabled":d.warehouse_job != null},on:{"click":function($event){$event.preventDefault();return _vm.deleteMaterial(d)}}},[_c('i',{staticClass:"fas fa-trash-alt"})]):_vm._e(),(material.date_amount && material.date_amount[0].m_id)?_c('a',{staticClass:"ml-1 ml-auto is-pulled-right is-size-7 pt-1",class:{ 'ml-auto': !_vm.am_allowed('request.can_delete') },attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.$parent.open_material_notes(
                        d,
                        'REQUEST',
                        material.IdST,
                        job.IdJob
                      )}}},[_c('span',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(d.request_notes)+" ")]),_c('i',{staticClass:"fas fa-align-left mt-1",class:{ 'ml-2': !d.request_notes }})]):_vm._e()])}),(material.date_amount && material.date_amount[0].m_id)?_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.addMaterialDateRequest(material, job, 1)}}},[_c('i',{staticClass:"fas fa-plus mr-1"}),_vm._v(" "+_vm._s(_vm.$t("add_date"))+" ")]):_vm._e()],2),_c('td',_vm._l((material.date_amount),function(d,indexu){return _c('div',{key:indexu},[_c('p',{staticStyle:{"height":"36px"}},[(d.created_by_user)?_c('small',{staticClass:"ml-2 has-text-grey-light"},[_c('i',{staticClass:"fas fa-user-edit fa-sm mt-1 mr-1"}),_vm._v(" "+_vm._s(d.created_by_user)+" ")]):_vm._e()])])}),0),_c('td',{staticClass:"is-centered has-text-right is-size-5",class:{
                  'has-text-danger': _vm.totalMaterials(material) < material.Qty,
                },attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(material.date_amount[0].m_id ? _vm.totalMaterials(material) : "")+" ")]),_c('td',{staticClass:"is-size-5 is-centered"},[_vm._v(" "+_vm._s(material.Qty)+" ")])])}),0):_vm._e(),_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":"5"}},[(!_vm.can_be_finalised)?_c('div',{staticClass:"has-text-warning-dark"},[_c('i',{staticClass:"fa fa-exclamation-triangle"}),_vm._v(" There are materials that are not connected to the Tetris System! ")]):_vm._e(),(_vm.unsaved_changes)?_c('div',{staticClass:"has-text-danger-dark"},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" There are material changes that are not saved in the Tetris System! ")]):_vm._e()]),_c('td',{attrs:{"colspan":"3"}})]),_c('tr',[_c('td',{attrs:{"colspan":"8"}},[_c('div',{staticClass:"  "},[_c('div',{staticClass:"is-flex is-justify-content-space-around general-steps-wrap mb-3"},[(job.RequestJobStatus >= 0)?_c('div',{staticClass:"general-step-item"},[_c('span',{staticClass:"general-step mb-1",class:{
                          'general-step-success': job.RequestJobStatus > 0,
                        }},[(job.RequestJobStatus > 0)?_c('i',{staticClass:"fas fa-check fa-sm is-text-palete-green-3"}):_c('i',{staticClass:"far fa-clock has-text-grey-light"})]),_c('span',{staticClass:"general-step-text"},[(job.RequestJobStatus > 0)?_c('small',{staticClass:"is-text-palete-green-3"},[_vm._v(" Logistics Notified ")]):(_vm.am_allowed('request.can_finalize_request'))?_c('a',{key:"a",staticClass:" ",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.finish_request(job)}}},[_c('small',{staticClass:"has-text-grey-light"},[_vm._v(" Notify logistics ")])]):_vm._e()])]):_vm._e(),(job.RequestJobStatus > 0)?_c('div',{staticClass:"general-step-item"},[_c('span',{staticClass:"general-step mb-1",class:{
                          'general-step-success':
                            _vm.not_accepted_warehouse_amount == 0,
                        }},[(_vm.created_warehouse_jobs.total > 0)?_c('i',{staticClass:"fas fa-check fa-sm is-text-palete-green-3"}):_c('i',{staticClass:"far fa-clock is-text-palete-orange-3"})]),_c('span',{staticClass:"general-step-text"},[(_vm.not_accepted_warehouse_amount == 0)?_c('small',{staticClass:"is-text-palete-green-3"},[_vm._v(" Confirmed ")]):_c('small',{staticClass:"is-text-palete-orange-3"},[_c('i',{staticClass:"fas fa-exclamation-triangle mr-2"}),_vm._v(" "+_vm._s(isNaN(_vm.not_accepted_warehouse_amount) ? 0 : _vm.not_accepted_warehouse_amount)+" Materials not proposed. ")])]),(_vm.created_warehouse_jobs.total > 0)?_c('span',{staticClass:"general-step-text is-flex width-100 is-justify-content-space-around mt-1"},[_c('span',{staticClass:"is-text-palete-green-3"},[_c('i',{staticClass:"fas fa-file",attrs:{"title":"Confirmed jobs"}}),_vm._v(" "+_vm._s(_vm.created_warehouse_jobs.confirmed)+" ")]),_c('span',{staticClass:"has-text-grey-light"},[_c('i',{staticClass:"far fa-file",attrs:{"title":"Pending jobs"}}),_vm._v(" "+_vm._s(_vm.created_warehouse_jobs.tentative)+" ")])]):_vm._e()]):_vm._e(),(job.RequestJobStatus > 0)?_c('div',{staticClass:"general-step-item"},[_c('span',{staticClass:"general-step mb-1",class:{
                          'general-step-success': job.RequestJobStatus == 1,
                        }},[(job.RequestJobStatus > 1)?_c('i',{staticClass:"fas fa-check fa-sm is-text-palete-green-3"}):_c('i',{staticClass:"far fa-clock is-text-palete-orange-3"})]),_c('span',{staticClass:"general-step-text"},[(job.RequestJobStatus >= 1)?_c('a',{staticClass:"is-text-palete-green-3",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.finish_request(job, 2)}}},[_c('small',[_vm._v(" Planning ")])]):_c('small',{staticClass:"is-text-palete-orange-3"},[_vm._v(" Continue planning ")])])]):_vm._e()])])])])])])}),_c('br'),_c('br'),_c('br'),_c('br')],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }