<template>
  <div
    class="container is-fluid general-component-wrap-background min-height-100vh"
  >
    <div class="columns mt-0 pt-5">
      <div class="column pt-0 pr-4 is-12" style="margin-top: -0.75rem">
        <div class="is-flex py-2"></div>
        <!-- <pre>{{$parent.jobs_requests}}</pre> -->
        <table
          style="border-collapse: collapse"
          class="add-materials-table is-fullwidth is-narrow"
          v-for="job in $parent.jobs_requests"
          :key="job.Number"
        >
          <thead class="th-sticky">
            <tr>
              <td class="m-0 p-0" colspan="9">
                <p
                  class="is-size-5 has-text-info-dark is-block has-fullwidth p-2"
                  :class="{
                    'request-confirmed':
                      job.JobState.ID == 1 || job.JobState.ID == 6,
                    'request-not-confirmed':
                      job.JobState.ID != 1 && job.JobState.ID != 6,
                  }"
                >
                  <i class="fas fa-boxes mr-2"></i>
                  <!-- Materials -->
                  <!-- Jobs -->
                  {{ $t("request") }}
                  <span class="has-text-grey">{{ job.Number }}</span>
                  <small
                    class="ml-2 has-text-grey-light"
                    style="font-weight: normal"
                    >{{ job.Arranger.DisplayName }}</small
                  >

                  <span class="is-pulled-right mr-2" v-if="job.JobState">
                    <i
                      class="far fa-clock mr-2"
                      v-if="job.JobState.ID != 1 && job.JobState.ID != 6"
                    ></i>
                    <i class="fa fa-check-square" v-else></i>
                    <span v-if="job.JobState.ID != 1 && job.JobState.ID != 6">{{
                      job.JobState.Caption
                    }}</span>
                  </span>
                </p>
              </td>
            </tr>
            <!-- <tr :class="{
                                     'general-component-wrap-background': new Date() < new Date(job.dates[1]),

                                     'has-background-warning-light': new Date() > new Date(job.dates[1])
                                    }">
                            <td colspan="4">
                                <b class="has-text-info-dark" style="">{{job.Caption}}</b>
                            </td>
                            <td width="250px">
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr> -->
            <tr
              class="has-text-info-dark general-component-wrap-background"
              v-if="job.expanded"
            >
              <!-- <th width="10%"></th> -->
              <th class="has-text-info-dark" width="7%">{{ $t("group") }}</th>
              <th class="has-text-info-dark" width="25%">
                {{ $t("material") }}
              </th>
              <th class="has-text-info-dark" width="100px" style="">
                {{ $t("amount") }}
              </th>
              <th class="has-text-info-dark" width="25%">
                {{ $t("from_until") }}
                <span class="is-pulled-right">
                  {{ $t("notes") }}
                </span>
              </th>
              <th width="10%"></th>
              <th
                width="200px"
                colspan="2"
                class="has-text-right has-text-info-dark"
              >
                {{ $t("accepted") }}
              </th>
              <th class="has-text-info-dark has-text-centered">
                {{ $t("requested") }}
              </th>
            </tr>
          </thead>
          <tbody v-if="job.expanded">
            <tr
              v-for="(material, index) in job.materials"
              :key="index"
              :class="{
                'general-component-wrap-background':
                  material.date_amount && material.date_amount[0].m_id,
                'has-background-white':
                  !material.date_amount || !material.date_amount[0].m_id,
              }"
              class="tr-border has-text-dark"
            >
              <td class="is-size-7 is-centered">
                <b>{{ getGroup(index, job.materials) }}</b>
                <!-- {{material.Category}} -->
              </td>
              <td>
                <small class="has-text-grey-light">
                  {{ material.Category }} </small
                ><br />
                {{ material.Caption }}
              </td>
              <td class="pr-3">
                <div class="field">
                  <div
                    v-for="(d, indexa) in material.date_amount"
                    :key="indexa"
                    class="control is-hover"
                  >
                    <div class="">
                      <div
                        class="field has-addons mb-1 add-materials-table-input"
                        style="padding-right: 1px"
                      >
                        <p
                          class="control"
                          style="width: 100%"
                          :class="{
                            'material-assigned-warehouse':
                              d.warehouse_job != null ||
                              d.m_connected_project_id != null,
                          }"
                        >
                          <input
                            class="input is-small is-hover"
                            style="width: 80px"
                            :class="{
                              'general-component-wrap-background':
                                d.Qty == d.QtyWas &&
                                !has_changed_dates(d.dates, d.date_was),
                            }"
                            v-model="d.Qty"
                            type="number"
                            v-if="
                              d.warehouse_job == null &&
                              d.m_connected_project_id == null &&
                              am_allowed('request.can_edit')
                            "
                          />
                          <small
                            v-if="
                              d.warehouse_job == null &&
                              !am_allowed('request.can_edit')
                            "
                            class="ml-1"
                          >
                            {{ d.Qty }}
                          </small>
                          <small
                            v-if="d.warehouse_job || d.m_connected_project_id"
                            class="p-1 has-text-dark"
                            >{{ d.Qty }}</small
                          >
                        </p>
                        <p class="control">
                          <a
                            v-if="
                              d.Qty != d.QtyWas &&
                              d.warehouse_job == null &&
                              d.m_connected_project_id == null
                            "
                            @click.prevent="d.Qty = d.QtyWas"
                            class="button is-small"
                          >
                            <i class="fas fa-times"></i>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div
                  v-for="(d, indexu) in material.date_amount"
                  :key="indexu"
                  class="is-flex is-hover"
                >
                  <div
                    class="tile is-6 field has-addons has-addons-centered mb-1 is-fullwidth add-materials-table-input"
                    style="padding-right: 1px; min-width: 300px"
                  >
                    <p
                      class="control is-expanded"
                      :class="{
                        'material-assigned-warehouse':
                          d.warehouse_job != null ||
                          d.m_connected_project_id != null,
                      }"
                    >
                      <span
                        v-if="
                          d.warehouse_job == null &&
                          d.m_connected_project_id == null
                        "
                      >
                        <date-picker
                          v-if="am_allowed('request.can_edit')"
                          @change="fixIfWrong(d)"
                          v-model="d.dates"
                          format="ddd DD.MM.YYYY"
                          :lang="$i18n.locale"
                          type="date"
                          value-type="date"
                          :disabled-date="disabledOutOfJobDates"
                          range
                          :input-class="{
                            'input is-small is-fullwidth': true,
                            'general-component-wrap-background':
                              !has_changed_dates(d.dates, d.date_was),
                          }"
                        ></date-picker>
                        <small v-else class="pl-1">
                          {{ d.dates[0] | customdate }} -
                          {{ d.dates[1] | customdate }}
                        </small>
                      </span>
                      <small v-else class="p-1 has-text-dark"
                        >{{ d.dates[0] | customdate }} -
                        {{ d.dates[1] | customdate }}</small
                      >
                    </p>
                    <p class="control">
                      <a
                        v-if="
                          has_changed_dates(d.dates, d.date_was) &&
                          d.warehouse_job == null
                        "
                        @click.prevent="d.dates = d.date_was"
                        class="button is-small"
                      >
                        <i class="fas fa-times"></i>
                      </a>
                    </p>
                    <!-- <p class="control">
                                            <a v-if="has_changed_dates(d.dates, d.date_was)" 
                                                @clickvalue-type="format""button is-success is-small" href="">
                                                <i class="far fa-save"></i>
                                            </a>
                                        </p> -->
                  </div>
                  <a
                    v-if="
                      (has_changed_dates(d.dates, d.date_was) ||
                        d.Qty != d.QtyWas) &&
                      d.warehouse_job == null
                    "
                    @click.prevent="addMaterialDateRequest(material, job, 0)"
                    class="button is-success is-small ml-1"
                  >
                    <i class="far fa-save"></i>
                  </a>
                  <a
                    v-if="
                      d.warehouse_job == null &&
                      d.m_connected_project_id == null &&
                      am_allowed('request.can_delete')
                    "
                    @click.prevent="deleteMaterial(d)"
                    :disabled="d.warehouse_job != null"
                    class="has-text-danger ml-auto pt-1 is-pulled-right"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                  <!-- v-if="material.date_amount && material.date_amount[0].m_id" @click.prevent="$parent.open_material_notes(d, 'REQUEST', material.IdST, job.IdJob)"  -->
                  <a
                    v-if="material.date_amount && material.date_amount[0].m_id"
                    @click.prevent="
                      $parent.open_material_notes(
                        d,
                        'REQUEST',
                        material.IdST,
                        job.IdJob
                      )
                    "
                    :class="{ 'ml-auto': !am_allowed('request.can_delete') }"
                    href=""
                    class="ml-1 ml-auto is-pulled-right is-size-7 pt-1"
                  >
                    <span class="mx-1">
                      {{ d.request_notes }}
                    </span>
                    <i
                      :class="{ 'ml-2': !d.request_notes }"
                      class="fas fa-align-left mt-1"
                    ></i>
                  </a>
                </div>
                <a
                  href=""
                  v-if="material.date_amount && material.date_amount[0].m_id"
                  @click.prevent="addMaterialDateRequest(material, job, 1)"
                  class=""
                >
                  <i class="fas fa-plus mr-1"></i> {{ $t("add_date") }}
                </a>
              </td>
              <td>
                <div v-for="(d, indexu) in material.date_amount" :key="indexu">
                  <p style="height: 36px">
                    <small
                      v-if="d.created_by_user"
                      class="ml-2 has-text-grey-light"
                    >
                      <i class="fas fa-user-edit fa-sm mt-1 mr-1"></i>
                      {{ d.created_by_user }}
                    </small>
                  </p>
                </div>
              </td>

              <td
                colspan="2"
                class="is-centered has-text-right is-size-5"
                :class="{
                  'has-text-danger': totalMaterials(material) < material.Qty,
                }"
              >
                {{
                  material.date_amount[0].m_id ? totalMaterials(material) : ""
                }}
              </td>
              <td class="is-size-5 is-centered">
                {{ material.Qty }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">
                <div class="has-text-warning-dark" v-if="!can_be_finalised">
                  <i class="fa fa-exclamation-triangle"></i>
                  There are materials that are not connected to the Tetris
                  System!
                </div>
                <div class="has-text-danger-dark" v-if="unsaved_changes">
                  <i class="fa fa-download"></i>
                  There are material changes that are not saved in the Tetris
                  System!
                </div>
              </td>
              <td colspan="3">
                <!--<a @click.prevent="finish_request(job)" href="#" key="a"
									v-if="am_allowed('request.can_finalize_request')"
									:class="{'is-success': job.RequestJobStatus == 1}"
									class="button is-info is-pulled-right">
									<span v-if="job.RequestJobStatus == 1">
										<i class="fa fa-check mr-2"></i>
										{{$t('request_dates_finalized')}}
									</span> 
									<span v-else>{{$t("finish_request")}}</span>
								</a>-->
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <div class="  ">
                  <div
                    class="is-flex is-justify-content-space-around general-steps-wrap mb-3"
                  >
                    <div
                      class="general-step-item"
                      v-if="job.RequestJobStatus >= 0"
                    >
                      <span
                        :class="{
                          'general-step-success': job.RequestJobStatus > 0,
                        }"
                        class="general-step mb-1"
                      >
                        <i
                          v-if="job.RequestJobStatus > 0"
                          class="fas fa-check fa-sm is-text-palete-green-3"
                        ></i>
                        <i v-else class="far fa-clock has-text-grey-light"></i>
                      </span>
                      <span class="general-step-text">
                        <small
                          v-if="job.RequestJobStatus > 0"
                          class="is-text-palete-green-3"
                        >
                          Logistics Notified
                        </small>
                        <a
                          v-else-if="am_allowed('request.can_finalize_request')"
                          @click.prevent="finish_request(job)"
                          href="#"
                          key="a"
                          class=" "
                        >
                          <small class="has-text-grey-light">
                            Notify logistics
                          </small>
                        </a>
                      </span>
                    </div>
                    <div
                      v-if="job.RequestJobStatus > 0"
                      class="general-step-item"
                    >
                      <span
                        :class="{
                          'general-step-success':
                            not_accepted_warehouse_amount == 0,
                        }"
                        class="general-step mb-1"
                      >
                        <i
                          v-if="created_warehouse_jobs.total > 0"
                          class="fas fa-check fa-sm is-text-palete-green-3"
                        ></i>
                        <i
                          v-else
                          class="far fa-clock is-text-palete-orange-3"
                        ></i>
                      </span>
                      <span class="general-step-text">
                        <small
                          class="is-text-palete-green-3"
                          v-if="not_accepted_warehouse_amount == 0"
                        >
                          Confirmed
                        </small>
                        <small v-else class="is-text-palete-orange-3">
                          <i class="fas fa-exclamation-triangle mr-2"></i>
                          {{
                            isNaN(not_accepted_warehouse_amount)
                              ? 0
                              : not_accepted_warehouse_amount
                          }}
                          Materials not proposed.
                        </small>
                      </span>
                      <span
                        v-if="created_warehouse_jobs.total > 0"
                        class="general-step-text is-flex width-100 is-justify-content-space-around mt-1"
                      >
                        <span class="is-text-palete-green-3">
                          <i title="Confirmed jobs" class="fas fa-file"></i>
                          {{ created_warehouse_jobs.confirmed }}
                        </span>
                        <span class="has-text-grey-light">
                          <i title="Pending jobs" class="far fa-file"></i>
                          {{ created_warehouse_jobs.tentative }}
                        </span>
                      </span>
                    </div>
                    <div
                      v-if="job.RequestJobStatus > 0"
                      class="general-step-item"
                    >
                      <span
                        :class="{
                          'general-step-success': job.RequestJobStatus == 1,
                        }"
                        class="general-step mb-1"
                      >
                        <i
                          v-if="job.RequestJobStatus > 1"
                          class="fas fa-check fa-sm is-text-palete-green-3"
                        ></i>
                        <i
                          v-else
                          class="far fa-clock is-text-palete-orange-3"
                        ></i>
                      </span>
                      <span class="general-step-text">
                        <a
                          v-if="job.RequestJobStatus >= 1"
                          @click.prevent="finish_request(job, 2)"
                          class="is-text-palete-green-3"
                          href=""
                        >
                          <small> Planning </small>
                        </a>
                        <small v-else class="is-text-palete-orange-3">
                          Continue planning
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import "vue2-datepicker/locale/de";
import "vue2-datepicker/locale/it";
import "vue2-datepicker/locale/en";

import axios from "axios";
import socketMixin from "@/socket-mixin.js";
import permissionMixin from "@/permissions-mixin.js";
import socket from "@/socket.js";
// var CryptoJS = require("crypto-js");
export default {
  mixins: [socketMixin, permissionMixin],
  components: {},
  data() {
    return {
      newWarehouse: 0,
      time3: new Date(),
      material_request: [],
      format: "YYYY-MM-DD",
      job_creation_ended: true,
    };
  },
  created() {
    if (this.am_allowed("request.can_read")) {
      // Check if have permissions can_see
      this.$document.title = "Request: " + this.$parent.event_details.Caption;

      if (this.$route.params.id && this.$parent.event_jobs.length == 0) {
        this.$parent.event_jobs = [];
        this.$parent.event_details.Jobs = [];
        this.jobs = [];
        this.$parent.currentEvent = this.$route.params.id;
        this.$parent.getProject();
      }
      this.$parent.getItems();

      this.$parent.app_lang = this.$i18n.locale;
      this.$parent.app_route = this.$parent.get_url();
    } else {
      if (this.am_allowed("request.can_read")) {
        this.$router.push("/request-accept/" + this.$route.params.id);
      } else if (this.am_allowed("warehouse.can_read")) {
        this.$router.push("/request-accept/" + this.$route.params.id);
      } else if (this.am_allowed("parameter.can_read")) {
        this.$router.push("/delivery-parameters/" + this.$route.params.id);
      } else if (this.am_allowed("planning.can_read")) {
        this.$router.push("/delivery-plan/" + this.$route.params.id);
      } else {
        this.$router.push("/");
      }
    }
  },
  mounted() {
    socket.on("someone said", this.socket_listener);
  },
  beforeDestroy() {
    socket.off("someone said", this.socket_listener);
  },
  methods: {
    socket_listener(data) {
      console.log("socket data", data.data);
      let operator =
        '<p class = "has-text-primary-dark">' + " " + data.by.name + "</p>";
      if (data.type == "add material") {
        let message = "";
        if (data.data[2] == "add date")
          message =
            "<div>" +
            this.$t("material") +
            ": " +
            data.data[1].Caption +
            this.$t("has_been_added_by") +
            " " +
            operator +
            "</div>";
        else if (data.data[2] == "edit date")
          message =
            this.$t("material") +
            ": " +
            data.data[1].Caption +
            this.$t("has_been_editet_tetris_ej") +
            " " +
            operator +
            "</div>";
        else if (data.data[2] == "localedit date")
          message =
            this.$t("material") +
            ": " +
            data.data[1].Caption +
            this.$t("has_been_editet_tetris") +
            " " +
            operator +
            "</div>";
        else if (data.data[2] == "sync date")
          message =
            this.$t("material") +
            ": " +
            data.data[1].Caption +
            this.$t("has_been_changed_in_tetris") +
            " " +
            operator +
            "</div>";
        this.$toast("<div>" + message + "</div>", "info");
        //refreshing info
        this.$toasted.show(
          "<div>" + this.$t("please_reload_page_request") + "</div>",
          {
            position: "bottom-right",
            keepOnHover: true,
            type: "error",
            theme: "outline",
            html: true,
            icon: {
              name: "info",
            },
            action: {
              icon: "sync",
              onClick: () => {
                window.location.reload();
              },
            },
          }
        );
      } else if (data.type == "add note") {
        this.$toast(
          "<div>" +
            this.$t("new_note_added_by") +
            " " +
            operator +
            " " +
            "</div>",
          "info"
        );
      } else if (data.type == "delete note") {
        this.$toast(
          "<div>" +
            this.$t("note_deleted_by") +
            " " +
            operator +
            " " +
            "</div>",
          "info"
        );
      } else if (data.type == "edit note") {
        this.$toast(
          "<div>" + this.$t("note_edited_by") + " " + operator + " " + "</div>",
          "info"
        );
      }
    },
    fixIfWrong(d) {
      // console.log('should fix', d)
      let user = JSON.parse(localStorage.tetris_user);
      d.dates = [
        this.$moment(new Date(d.dates[0]))
          .add(user.timezone_offset, "hours")
          .toDate(),
        this.$moment(new Date(d.dates[1]))
          .add(user.timezone_offset, "hours")
          .toDate(),
      ];
      // console.log('should be fixed', d.dates)
    },
    sendToOthersTheMaterial(m) {
      socket.emit("tell others", { type: "add material", data: m });
    },
    // Adds to tetris job material list
    addTetrisMaterial(post_data, mat) {
      console.log(post_data, mat, this.$parent.jobs_requests[0]);
      axios
        .post(this.$tetris_server + "/add/job/material", {
          material: post_data,
          list: mat.date_amount.map((mm) => {
            return {
              Qty: mm.Qty,
              qty_changed: mm.QtyWas != mm.Qty,
              dates: mm.dates,
              m_guid: mm.m_guid,
              dates_changed: this.has_changed_dates(mm.dates, mm.date_was),
              created_by: mm.created_by,
            };
          }),
          project: this.$parent.event_details,
          job: {
            ID: this.$parent.jobs_requests[0].ID,
            Caption: this.$parent.jobs_requests[0].Caption,
          },
        })
        .then((resp) => {
          console.log(resp.data);
          if (this.job_creation_ended)
            setTimeout(() => {
              // window.location.reload()
              this.$parent.getProject();
              this.$toast(
                this.$t("material_save_toaster", { mat: mat.Caption })
              );
            }, 500);
        });
    },
    addMaterialDateRequest(m, jb, add = 1) {
      this.$fire({
        text: this.$t("are_you_sure"),
        showCancelButton: true,
        confirmButtonText: this.$t("yes"),
        cancelButtonText: this.$t("no"),
        width: 300,
        customClass: {
          confirmButton: "button is-success is-small",
          cancelButton: "button is-small",
        },
      }).then((response) => {
        if (response.value) {
          this.$parent.jobs.forEach((j) => {
            if (
              j.ID == jb.ID &&
              j.Caption.toLowerCase().includes("- request")
            ) {
              // only the requests
              j.materials.forEach((mat) => {
                this.job_creation_ended = false;
                if (mat.IdST == m.IdST) {
                  // console.log(m)
                  var post_data = {
                    IdJob: j.ID,
                    IdStockType: m.IdST,
                    IdStockType2JobGroup: m.IdST2JG,
                    IdStockType2JobType: m.IdST2JT,
                    Quantity: 0,
                  };
                  /// a new date will be added to the tetris materials list with a new date
                  if (add == 1) {
                    // let user = JSON.parse(localStorage.tetris_user)

                    m.dates = [
                      this.$moment(this.$parent.event_details.StartDate), //.add(user.timezone_offset, "hours"),
                      this.$moment(this.$parent.event_details.EndDate), // .add(user.timezone_offset, "hours")

                      // new Date(this.$parent.event_details.StartDate),
                      // new Date(this.$parent.event_details.EndDate)
                    ];

                    // add by default 1 qty for the new date
                    let default_qty = 1;
                    if (this.totalMaterials(mat) + default_qty > mat.Qty)
                      post_data.Quantity =
                        this.totalMaterials(mat) + default_qty - mat.Qty;

                    if (
                      post_data.Quantity >= 0 &&
                      confirm(this.$t("confirm_increase_material_amount_ej"))
                    )
                      axios
                        .post(
                          this.$ej_server + "/api.json/Items/Book",
                          post_data,
                          { headers: this.$ej_headers }
                        )
                        .then(() => {
                          // console.log('quantity 0 or bigger', r.data.Message)
                          if (add == 1) {
                            let new_mat = {
                              Qty: default_qty,
                              QtyWas: default_qty,
                              dates: m.dates,
                              date_was: m.date_was,
                              warehouse_job: m.warehouse_job,
                              m_id: m.m_id,
                              qty_changed: false,
                              m_guid: m.m_guid,
                            };
                            this.$toast(this.$t("mat_amount_increased_by_one"));
                            mat.date_amount.push({ ...new_mat });
                          }
                          this.addTetrisMaterial(post_data, mat);
                          console.log("post_data", post_data, mat);
                          this.sendToOthersTheMaterial([
                            post_data,
                            mat,
                            "add date",
                          ]);
                        });
                  } else {
                    // a material date is being edited
                    let should_continue = true;
                    if (this.totalMaterials(mat) > mat.Qty) {
                      should_continue = confirm(
                        this.$t("confirm_increase_material_amount_ej")
                      );
                      if (should_continue) {
                        post_data.Quantity = this.totalMaterials(mat) - mat.Qty;
                        // console.log('editing', post_data.Quantity)
                        axios
                          .post(
                            this.$ej_server + "/api.json/Items/Book",
                            post_data,
                            { headers: this.$ej_headers }
                          )
                          .then(() => {
                            this.$toast(this.$t("mat_amount_increased_by_one"));

                            // Add to tetris
                            this.addTetrisMaterial(post_data, mat);
                            this.sendToOthersTheMaterial([
                              post_data,
                              mat,
                              "edit date",
                            ]);
                          });
                      }
                    }
                    // 2. else easy job doesn't need to be updated just tetris
                    else {
                      if (should_continue) {
                        this.addTetrisMaterial(post_data, mat);
                        this.sendToOthersTheMaterial([
                          post_data,
                          mat,
                          m.m_id ? "localedit date" : "sync date",
                        ]);
                      }
                    }
                    // console.log('tetris update')
                  }
                }

                this.job_creation_ended = true;
              });
            }
          });
        }
      });
    },
    addWarehouse(id) {
      let w = {};
      w = this.$parent.warehouses.find((e) => {
        if (e.id == id) return e;
      });
      this.$parent.requestedWarehouses.push(w);
      this.newWarehouse = 0;
    },
    removeWarehouse(id) {
      this.$parent.requestedWarehouses =
        this.$parent.requestedWarehouses.filter((el) => {
          return el.id != id;
        });
    },
    isAdded(id) {
      let a = false;
      this.$parent.requestedWarehouses.map((w) => {
        if (w.IdStock == id) return (a = true);
      });
      return a;
    },
    totalMaterials(m) {
      var t = 0;
      if (m.date_amount)
        m.date_amount.forEach((da) => {
          t += parseFloat(da.Qty);
        });
      return t;
    },
    getGroup(index, materials) {
      if (
        (index > 0 && materials[index].Group != materials[index - 1].Group) ||
        index == 0
      )
        return materials[index].Group;
      return "";
    },
    disabledOutOfJobDates(j) {
      let dt1 = new Date(this.$parent.event_details.StartDate);
      let dt2 = new Date(this.$parent.event_details.EndDate);
      dt1 = new Date(dt1.getTime() - 1 * 24 * 60 * 60 * 1000); // one day before
      dt2 = new Date(dt2.getTime() + 1 * 24 * 60 * 60 * 1000); // one day after
      return j < dt1 || j > dt2;
    },
    has_changed_dates(d, dw) {
      return JSON.stringify(d) != JSON.stringify(dw);
    },
    cancelcallback() {},
    deleteMaterial(d) {
      //    console.log('d', d, d.warehouse_job)
      //    console.log('m', m)
      if (d.warehouse_job != null) {
        this.$toast("You can not delete this material", "danger");
      } else {
        this.$fire({
          // title: 'Are you sure?',
          text: this.$t("delete_material"),
          showCancelButton: true,
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
          width: 300,
          customClass: {
            confirmButton: "button is-success is-small",
            cancelButton: "button is-small",
          },
        }).then((response) => {
          if (response.value) {
            axios
              .post(this.$tetris_server + "/unsync/job/material", {
                material: d,
                project: this.$parent.event_details,
                job: {
                  ID: this.$parent.jobs_requests[0].ID,
                  Caption: this.$parent.jobs_requests[0].Caption,
                  IdJob: this.$parent.jobs_requests[0].ID,
                },
                //job: {'ID': this.$parent.jobs_requests[0].ID, 'Caption': this.$parent.jobs_requests[0].Caption, 'IdJob': this.$parent.jobs_requests[0].ID}
              })
              .then((resp) => {
                if (resp.data == 1) {
                  // TO DO - update the data without refresh
                  window.location.reload();
                  let l;
                  l = [
                    ...this.$parent.jobs.map((job) => {
                      job.materials = job.materials.map((mat) => {
                        mat.date_amount = mat.date_amount.filter(
                          (da) => da.m_guid != d.m_guid
                        );
                        return mat;
                      });
                      return job;
                    }),
                  ];
                  this.$parent.jobs = l;
                }
              });
          }
        });
      }
    },
    finish_request(job, status_id = 1) {
      if (status_id) {
        job.status_id = status_id;
      }
      console.log("send this: ", job);
      if (job.RequestJobStatus >= 0) {
        axios.post(this.$tetris_server + "/finish/request", job).then(() => {
          // notify other sockets about this change
          // finally refresh window (some timeout)

          if (status_id == 2) {
            if (confirm("Are you sure you want to continue?"))
              // revert jobs statuses:
              // request -> tentative
              // warehouse jobs -> confirmed
              var list = [];
            this.$parent.jobs.map((j) => {
              if (j.Caption.toLowerCase().includes("- request"))
                list.push(this.saveStatus(j, 6));
              else if (j.Caption.toLowerCase().includes("- w:"))
                list.push(this.saveStatus(j, 1));
            });
            Promise.all(list).then(() => {
              window.location.reload();
            });
          } else {
            window.location.reload();
          }
        });
      }
    },
    async saveStatus(current_job, jstate) {
      return await this.$parent
        .postEJDatas("Jobs/Save/", {
          Address_Delivery: current_job.Address_Delivery,
          Caption: current_job.Caption,
          Contact_Delivery: current_job.Contact_Delivery,
          DayTimeIn: current_job.DayTimeIn,
          DayTimeOut: current_job.DayTimeOut,
          IdJob: current_job.IdJob,
          JobState: { IdJobState: jstate, ID: jstate },
          Project: current_job.Project,
          Stock: current_job.Stock,
        })
        .then(() => {
          // console.log(resp.data)
          // send to other sockets this change
          // client.emit('tellOthers', dataToEmit, function(error, message){
          // });
        });
    },
  },
  computed: {
    can_be_finalised() {
      let can = true;
      if (this.$parent.jobs_requests.length > 0)
        this.$parent.jobs_requests[0].materials.map((m) => {
          m.date_amount.map((da) => {
            if (!da.m_id) can = false;
          });
        });
      return can;
    },
    unsaved_changes() {
      let has = false;
      if (this.$parent.jobs_requests.length > 0)
        this.$parent.jobs_requests[0].materials.map((m) => {
          m.date_amount.map((da) => {
            if (
              da.m_id &&
              (da.Qty != da.QtyWas ||
                this.has_changed_dates(da.dates, da.date_was))
            )
              has = true;
          });
        });
      return has;
    },
    itemGroups() {
      var groups = [];
      this.$parent.jobs.forEach((j) => {
        j.materials.forEach((m) => {
          if (!groups.includes(m.Group)) groups.push(m.Group);
        });
      });
      return groups;
    },
    jobGroupsMaterials() {
      var jobs = [];
      this.$parent.jobs.forEach((j) => {
        let job = j;
        job.groups = [];
        j.materials.forEach((m) => {
          if (!job.groups.includes(m.Group))
            job.groups.push({ name: m.Group, materials: [] });
        });
        jobs.push(job);
      });
      return jobs;
    },
    not_accepted_warehouse_amount() {
      // return 0
      let amount = 0;
      if (this.$parent.ej_project_materials_requested.length) {
        this.$parent.ej_project_materials_requested.forEach((ejm) => {
          if (ejm.date_amount)
            ejm.date_amount.forEach((da) => {
              if (da.warehouse_job == null) {
                amount = amount + parseInt(da.Qty);
              }
            });
          else amount += parseInt(ejm.Qty);
        });
      }
      return amount;
    },
    created_warehouse_jobs() {
      let w = {
        confirmed: 0,
        tentative: 0,
        total: 0,
      };
      this.$parent.jobs_warehouses.map((wj) => {
        if (wj.JobState.ID == 1) w.confirmed++;
        else if (wj.JobState.ID == 6) w.tentative++;
      });
      w.total = w.confirmed + w.tentative;
      return w;
    },
  },
};
</script>

<style>
.toasted.outline .action {
  color: red !important;
}

.send-icon {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background: #0cbc33;
}
.mx-datepicker-range {
  width: 100%;
}

.dates-table-input {
  background-color: #dff9ff;
}
.circle {
  border-radius: 12px;
  width: 24px;
  height: 24px;
  display: block;
  float: left;
  margin-right: 4px;
}

.tr-border {
  border-bottom: solid 1px #b2b2b2;
}
.tr-border > .is-centered {
  vertical-align: middle !important;
  text-align: center;
}
/* .request-component-wrap-background{
        background:#eff5f5;
    } */
.add-materials-table > tbody > tr:hover {
  background: rgb(248 253 253) !important;
}
.has-fullwidth {
  width: 100% !important;
}

.request-confirmed {
  background: #a7ebd8 !important;
  border: 1px solid #dedede;
  padding-left: 10px !important;
  margin-bottom: 10px;
}
.request-not-confirmed {
  background: #f7f5c6 !important;
}

.material-assigned-warehouse * {
  color: #3d52a3 !important;
  border: none !important;
}

.progress-container {
  width: 100%;
  margin: 2em auto;
}

.progress-steps {
  counter-reset: step;
}

.progress-steps li {
  list-style-type: none;
  width: 25%;
  float: left;
  font-size: 0.7em;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #999;
}

.progress-steps-checked:before {
  content: "\2713" !important;
  line-height: 24px;
}
.progress-steps li:before {
  width: 2.5em;
  height: 2.5em;
  content: "\22B7"; /* counter(step); */
  counter-increment: step;
  line-height: 23px;
  /* line-height: 2.5em; */
  border: 2px solid #999;
  display: block;
  text-align: center;
  margin: 0 auto 0.7em auto;
  border-radius: 50%;
  background-color: white;
  font-weight: 700;
}

.progress-steps li:after {
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #999;
  top: 1.3em;
  left: -50%;
  z-index: -1;
}

.progress-steps li:first-child:after {
  content: none;
}

.progress-steps li.active {
  color: #1f8958;
}

.progress-steps li.active:before {
  border-color: #1f8958;
}

.progress-steps li.active + li:after {
  background-color: #1f8958;
}

.general-step {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  border: solid 1px #8694a4;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.general-step-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.general-step-text {
  font-size: 0.7em !important;
  text-transform: uppercase;
}

.general-step-success {
  border-color: forestgreen !important;
}

.is-hover:hover {
  background: white;
}
</style>
